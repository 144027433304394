import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../src/pages/home-page.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  }
];

const router = createRouter({
  history: createWebHistory(), // This replaces the `mode: 'history'` in Vue 2
  routes,
});

export default router;
