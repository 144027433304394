import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'maz-ui/css/main.css'

const app = createApp(App);

// Use Maz-UI
// app.use(MazUi);

// Mount the app
app.use(router).mount('#app');

