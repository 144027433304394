<template>
  <div class="logo-container">
    <img src="/images/logo.png" alt="Logo" class="logo" ref="logoRef">
    <h2 class="custom-title">Enter Roll code to begin</h2> <!-- Title added here -->
    <!-- MazInputCode for Roll ID -->
    <MazInputCode :size="inputSize" class="custom-input-code" v-model="code" :codeLength="6" acceptAlpha @completed="navigateToRoll" />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import MazInputCode from 'maz-ui/components/MazInputCode';

export default {
  components: {
    MazInputCode
  },
  setup() {
    const code = ref('');
    const router = useRouter();
    const logoRef = ref(null);
    const inputSize = ref('md'); // Default size

    function updateInputSize() {
      const width = window.innerWidth;
      if (width < 500) {
        inputSize.value = 'mini';
      } else if (width < 700) {
        inputSize.value = 'xs';
      } else if (width < 900) {
        inputSize.value = 'sm';
      } else {
        inputSize.value = 'md';
      }
    }

    onMounted(() => {
      updateInputSize(); // Set initial size based on current window width
      window.addEventListener('resize', updateInputSize);
    });

    function navigateToRoll() {
      if (code.value.trim()) {
        router.push({ name: 'Roll', params: { code: code.value } });
      }
    }

    onMounted(() => {
      const logo = logoRef.value;
      logo.style.opacity = 0;
      logo.style.transform = 'scale(0.8)'; // Start at 80% size
      setTimeout(() => {
        logo.style.opacity = 1;
        logo.style.transform = 'scale(1)'; // Grow to 100%
        logo.style.transition = 'all 1.1s ease';
      }, 50);
    });

     // Separate onMounted for input fade-in
     onMounted(() => {
      const inputBox = document.querySelector('.custom-input-code');
      inputBox.style.opacity = 0; // Start from transparent
      setTimeout(() => {
        inputBox.style.opacity = 1; // Fade to fully visible
        inputBox.style.transition = 'opacity 1.1s ease';
      }, 100); // Begins after the logo transition completes
    });

     onMounted(() => {
      const title = document.querySelector('.custom-title');
      title.style.opacity = 0; // Start from transparent
      setTimeout(() => {
        title.style.opacity = 1; // Fade to fully visible
        title.style.transition = 'opacity 0.9s ease';
      }, 700); // Begins after the logo transition completes
    });

    // Setup event listener for code completion
    onMounted(() => {
      window.addEventListener('keydown', (event) => {
        if (event.key === "Enter") {
          navigateToRoll();
        }
      });
    });

    return { code, navigateToRoll, logoRef, inputSize };
  }
}
</script>

<style scoped>
body, html {
  margin: 0;
  height: 100%;
  background-color: black; /* Ensures the background covers any device */
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.logo {
  width: 50%; /* Adjust logo size to be responsive */
  height: auto;
  max-width: 300px; /* Maximum size */
  transition: opacity 2s ease, transform 2s ease;
}

.custom-title {
  color: #787878;
  margin: 20px 0;
  font-size: 32px; /* Responsive font size using viewport width */
  text-align: center; /* Ensures text is always centered */
  font-weight: 400;
  padding: 0 10px; /* Padding to ensure it doesn't touch the edges */
}

.custom-input-code /deep/ input {
  background-color: #212121 !important;
  color: white;
  border-radius: 12px; /* Rounded corners for aesthetic */
  width: 80%; /* Responsive width */
  max-width: 400px; /* Maximum width */
  padding: 10px; /* Comfortable tapping area */
}

.custom-input-code /deep/ input:focus {
  outline: 0px solid lightblue; /* Custom focus style */
}

.custom-input-code /deep/ div {
  border: 0px !important; /* Removes any unwanted borders */
}

@media (max-width: 600px) { /* Media query for mobile devices */
  .custom-title {
    font-size: 6vw; /* Slightly larger font size for better readability */
  }

  .logo {
    width: 70%; /* Larger relative size on smaller screens */
    max-width: 200px; /* Smaller absolute max size for small devices */
  }

  .custom-input-code /deep/ input {
    width: 90%; /* Takes up more space for ease of use */
  }
}
</style>