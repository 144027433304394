<template>
  <div class="bg-black">
    <transition :name="transitionName">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      transitionName: 'slide' // Define your transition name here
    };
  }
};
</script>
